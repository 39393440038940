import React from "react";
import "./about.scss";
import aboutImage from "../../assests/about/366dfba87b7804b93773ad563b5ea7ee.jpg";

const About = () => {
  return (
    <section className="container ">
      <div className="flex md:flex-row flex-col gap-10 justify-between lg:p-10 p5">
        <div className="image-container flex-1">
          <img src={aboutImage} className="about-img" alt=""></img>
          <p className="lg:text-3xl text-xl mt-10 font-bold ">Made with love in UAE.</p>
        </div>
        <div className="flex flex-col gap-5 flex-1 xl:mt-8 mt-0">
          <p>
            The Lorem Ipsumedit is all about best styles, the styles which are
            timeless and loved forever, we will bring exceptional style to
            shoppers & we don’t intend on stopping anytime soon.{" "}
          </p>
          <p>
            Our vision is fast-paced, forward-thinking and fashion-centered at
            its core, and all of our products reflect these ideals. All the
            products are cut-to-order with premium quality materials. Lorem Ipsumis
            comfort and confidence.{" "}
          </p>
          <p>
            We invite you to browse our collections to find just what you've
            been looking for. We also customise looks according to your choice.{" "}
          </p>
          <p>
            For customisation, please reach out to us via Instagram or email.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
