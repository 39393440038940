import React, { useEffect } from "react";
import "./checkout.scss";
import { useCartContext } from "../../context/cartContext";
import { RiShieldCrossLine } from "react-icons/ri";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import qr from "../../assests/payment/qr.png";
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA3EKM4mOUuWE74-xaKJeDuzox0ekplrjA",
  authDomain: "the-non-dit.firebaseapp.com",
  projectId: "the-non-dit",
  storageBucket: "the-non-dit.appspot.com",
  messagingSenderId: "1017386525662",
  appId: "1:1017386525662:web:0026383ca5d250460db34f",
  measurementId: "G-7MCKH424RF",
};
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const imageFetchingLogic = async (imageName) => {
  // console.log("we are inside imagefetching logic")
  const fileRef = ref(storage, imageName);
  const url = await getDownloadURL(fileRef);
  // console.log("url before returning", url)
  return url;
};
const fetchAllImageUrls = async (imageNames) => {
  const allUrls = {};

  for (const imageName of imageNames) {
    const url = await imageFetchingLogic(imageName);
    allUrls[imageName] = url;
  }

  return allUrls;
};

const Checkout = () => {
  const { cart, removeItem, totalAmount, updateQuantity } = useCartContext();

  const [imageUrls, setImageUrls] = useState({});
  const [images, setImages] = useState([]);

  useEffect(() => {
    async function loadAllUrls() {
      for (const c of cart) {
        setImages((prevImages) => [...prevImages, c.image]);
      }
    }

    loadAllUrls();
  }, [cart]);
  // useEffect(() => {
  //   async function loadAllUrls() {
  //     // console.log("imagesin 2nd", images)
  //     const urls = await fetchAllImageUrls(images);
  //     setImageUrls(urls);
  //   }

  //   loadAllUrls();
  // }, [images]);

  const getCurrentImageUrl = (imageName) => {
    return imageUrls[imageName];
  };
  const [activeStep, setActiveStep] = useState(1);
  let schema;

  if (activeStep === 1) {
    schema = yup.object().shape({
      email: yup.string().email("Invalid email").required("Email is required"),
    });
  } else if (activeStep === 2) {
    schema = yup.object().shape({
      firstName: yup.string().required("First Name is required"),
      lastName: yup.string().required("Last Name is required"),
      address1: yup.string().required("Address 1 is required"),
      address2: yup.string(),
      country: yup.string().required("Country is required"),
      zip: yup
        .string()
        .required("ZIP Code is required")
        .matches(/^\d{6}$/, "ZIP Code must be a 6-digit number"),
      city: yup.string().required("City is required"),
      state: yup.string().required("State is required"),
      phoneNumber: yup
        .string()
        .required("Phone Number is required")
        .matches(/^\d{10}$/, "Phone Number must be a 10-digit number"),
    });
  } else if (activeStep === 3) {
    schema = yup.object().shape({
      modeOfPayment: yup.string().required("Mode Of Payment is required"),
      transactionNumber: yup
        .string()
        .required("Transaction Number is required"),
    });
  } else {
    // For other steps, you can define appropriate validation schema
    schema = yup.object().shape({});
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      country: "",
      zip: "",
      city: "",
      state: "",
      phoneNumber: "",
      modeOfPayment: "",
      transactionNumber: "",
    },
  });

  const handleQuantityChange = (e, productId, size) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 1 && value <= 100) {
      updateQuantity(productId, value, size);
    }
  };
  const onSubmit = () => {
    handleSubmit((data) => {
      const formData = {
        personalDetails: data,
        orderDetails: cart,
        totalAmount: totalAmount,
        paymentRefNo: data.transactionNumber,
        modeOfPayment: data.modeOfPayment,
      };

      // Log the form data to the console
      if (activeStep === 3) {
        axios
          .post(
            " https://sharedclients.ocpl.tech/api/theNonDitEdit/addOrder",
            formData
          )
          .then((response) => {
            if (response.status === 200) {
              let timerInterval;
              Swal.fire({
                title: "Successfully Placed your order!",
                html: "I will close in <b></b> milliseconds.",
                timer: 2500,
                icon: "success",
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const b = Swal.getHtmlContainer().querySelector("b");
                  timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft();
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  window.location.href = "/";
                }
              });
            } else {
              Swal.fire({
                title: response.data,
                icon: "error",
              });
              return;
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
      completeStep(); // Move to the next step
    })();
  };

  const completeStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  const isActive = (step) => activeStep === step;
  const isCompleted = (step) => activeStep === step;

  const renderContent = (step, content) => {
    if (isActive(step) || isCompleted(step)) {
      return content;
    }
    return null;
  };
  // function ProductImage({ imageName, index }) {
  //   // console.log("indexthat is coming", index)
  //   const [imageUrl, setImageUrl] = useState(null);

  //   useEffect(() => {
  //     async function fetchImageUrl() {
  //       const url = await imageFetchingLogic(imageName);
  //       setImageUrl(url);
  //     }

  //     fetchImageUrl();
  //   }, [imageName]);

  //   if (!imageUrl) return null; // or a placeholder/loading image

  //   return (
  //     <img
  //       src={imageUrl}
  //       alt="Product"
  //       className="w-32"
  //     />
  //   );
  // }

  return (
    <section className="container flex gap-10 justify-around flex-wrap ">
      <div className="order-summary border-2 shadow-xl p-10 h-max">
        <h2 className="text-2xl font-semibold">Order Summary</h2>
        {cart.map((curElem, index) => {
          return (
            <div
              key={curElem.id + index}
              className="flex flex-col gap-10 products-summary"
            >
              <div className="flex w-full my-5 gap-10 justify-center items-center">
                <div className="flex flex-wrap gap-3 flex-1">
                  {/* <img
                    src={` https://sharedclients.ocpl.tech/theNonDitEdit/${curElem.image}`}
                    alt=""
                    className="w-32"
                  ></img> */}
                  <img
                    src={curElem.image}
                    alt=""
                    className="w-32"
                  ></img>

                  <div>
                    <p className="text-xl font-medium">{curElem.name}</p>
                    <p>{`Size: ${curElem.size}`}</p>
                  </div>
                </div>

                <div className="flex items-center gap-5 flex-col amount">
                  <p>{`$${curElem.quantity * curElem.price}`}</p>
                  <div className="flex border-2 shadow-xl focus-within:border-blue-500">
                    <label htmlFor="quantity" className="py-1 px-2">
                      Qty
                    </label>
                    <input
                      className="input-field flex-grow py-1 px-2 outline-none"
                      value={curElem.quantity}
                      onChange={(e) =>
                        handleQuantityChange(e, curElem.id, curElem.size)
                      }
                      type="number"
                      disabled
                    ></input>
                  </div>

                  <button
                    className="opacity-70"
                    onClick={() => removeItem(curElem.id, curElem.size)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        <div className="flex flex-col mt-8 amount-summary">
          <div className="flex flex-col gap-3 ">
            <div className="flex justify-between w-full flex-1">
              <h2>SubTotal</h2>
              <h2>{`$${totalAmount}`}</h2>
            </div>
            <div className="flex justify-between w-full flex-1">
              <h2>Tax</h2>
              <h2>0</h2>
            </div>
            <div className="flex justify-between w-full flex-1">
              <h2>Shipping</h2>
              <h2>-</h2>
            </div>
            <div className="flex justify-between w-full flex-1 text-xl font-semibold">
              <h2>Total</h2>
              <h2>{`$${totalAmount}`}</h2>
            </div>
            <hr />
          </div>
          <div className="flex gap-5 justify-center items-center mt-3">
            <RiShieldCrossLine />
            <p>SECURE SSL CHECKOUT</p>
          </div>
        </div>
      </div>
      <div className="checkout-form flex flex-col gap-5">
        <div
          className={`flex flex-col gap-5 border-2 shadow-xl p-10 email-form ${
            1 > activeStep ? "opacity-50" : ""
          }`}
        >
          <div className="flex justify-between items-center">
            <p className="text-2xl font-semibold">1. Your Email</p>
            <button
              className="border border-black px-3 py-0"
              onClick={() => setActiveStep(1)}
            >
              Edit
            </button>
          </div>

          {renderContent(
            1,
            <>
              <div>
                <div className={`flex flex-col gap-3`}>
                  <input
                    type="text"
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                    })}
                  />
                  <p className="text-red-600/100">
                    {errors.email ? errors.email.message : ""}
                  </p>
                </div>
                <p>
                  You'll receive receipts and notifications at this email
                  address.
                </p>
                <div></div>
                <button
                  className="email-btn"
                  type="submit"
                  onClick={() => onSubmit()}
                >
                  Continue
                </button>
              </div>
            </>
          )}
        </div>
        <div
          className={`flex flex-col gap-5 border-2 shadow-xl p-10 delivery-methods ${
            2 > activeStep ? "opacity-50" : ""
          }`}
        >
          <div className="flex justify-between items-center">
            <p className="text-2xl font-semibold">2. Delivery Methods</p>
            <button
              className="border border-black px-3 py-0"
              onClick={() => setActiveStep(2)}
            >
              Edit
            </button>
          </div>
          {renderContent(
            2,
            <>
              <div>
                <p className="uppercase">delivery option</p>
                <p className="opacity-50">
                  Shipping not available for this product
                </p>
              </div>
              <div className="flex flex-col">
                <p>Shipping Address</p>
                <div className="flex gap-3">
                  <input
                    type="text"
                    placeholder="First Name"
                    className="w-full"
                    {...register("firstName", {
                      required: true,
                    })}
                  />

                  <input
                    type="text"
                    placeholder="Last Name"
                    className="w-full"
                    {...register("lastName", {
                      required: true,
                    })}
                  />
                </div>
                <p className="text-red-600/100">
                  {errors.firstName ? errors.firstName.message : ""}
                </p>
                <p className="text-red-600/100">
                  {errors.lastName ? errors.lastName.message : ""}
                </p>
              </div>
              <input
                type="text"
                placeholder="Address 1"
                className="w-full"
                {...register("address1", {
                  required: true,
                })}
              />
              <p
                className={`${
                  errors.address1 ? "block" : "hidden"
                } text-red-600/100`}
              >
                {errors.address1 ? errors.address1.message : ""}
              </p>
              <input
                type="text"
                placeholder="Address 2"
                className="w-full"
                {...register("address2", {
                  required: true,
                })}
              />
              <p
                className={`${
                  errors.address2 ? "block" : "hidden"
                } text-red-600/100`}
              >
                {errors.address2 ? errors.address2.message : ""}
              </p>

              {/* <select className={`py-3 px-5 `}>
                <option value="NaN">Country</option>
                <option value="0">0</option>
                <option value="2">2</option>
                <option value="4">4</option>
                <option value="6">6</option>
                <option value="8">8</option>
              </select> */}
              <input
                type="text"
                placeholder="Country"
                className="w-full"
                {...register("country", {
                  required: true,
                })}
              />
              <p
                className={`${
                  errors.country ? "block" : "hidden"
                } text-red-600/100`}
              >
                {errors.country ? errors.country.message : ""}
              </p>
              <div className="flex sm:flex-row flex-col gap-1">
                <input
                  type="text"
                  placeholder="Zip"
                  className="sm:w-4/4 w-full "
                  {...register("zip", {
                    required: true,
                  })}
                />
                <input
                  type="text"
                  placeholder="City"
                  className="sm:w-4/4 w-full sm:mt-0 mt-5 "
                  {...register("city", {
                    required: true,
                  })}
                />
                {/* <select className={`py-3 px-5 sm:mt-0 mt-5`}>
                  <option value="NaN">State</option>
                  <option value="0">0</option>
                  <option value="2">2</option>
                  <option value="4">4</option>
                  <option value="6">6</option>
                  <option value="8">8</option>
                </select> */}

                <input
                  type="text"
                  placeholder="State"
                  className="sm:w-4/4 w-full sm:mt-0 mt-5 "
                  {...register("state", {
                    required: true,
                  })}
                />
              </div>
              <p
                className={`${
                  errors.zip ? "block" : "hidden"
                } text-red-600/100`}
              >
                {errors.zip ? errors.zip.message : ""}
              </p>
              <p
                className={`${
                  errors.city ? "block" : "hidden"
                } text-red-600/100`}
              >
                {errors.city ? errors.city.message : ""}
              </p>
              <p
                className={`${
                  errors.state ? "block" : "hidden"
                } text-red-600/100`}
              >
                {errors.state ? errors.state.message : ""}
              </p>

              <input
                type="text"
                placeholder="Phone Number"
                className="w-full "
                {...register("phoneNumber", {
                  required: true,
                })}
              />
              <p
                className={`${
                  errors.phoneNumber ? "block" : "hidden"
                } text-red-600/100`}
              >
                {errors.phoneNumber ? errors.phoneNumber.message : ""}
              </p>
              <button className="email-btn" onClick={() => onSubmit()}>
                Next
              </button>
            </>
          )}
        </div>
        <div
          className={`flex flex-col gap-5 border-2 shadow-xl p-10 payment-form ${
            3 > activeStep ? "opacity-50" : ""
          }`}
        >
          <div className="flex justify-between items-center">
            <p className="text-2xl font-semibold">3. Payments</p>
            <button
              className="border border-black px-3 py-0"
              onClick={() => setActiveStep(3)}
            >
              Edit
            </button>
          </div>
          {renderContent(
            3,
            <>
              <p className="text-black-600/100">
                *Complete the payment on the <b>QR Code </b>
                {/* or by directly{" "}
                <b>transferring to bank account</b> */}
                and Once done provide with the Mode of Payment and Transaction
                Number
              </p>
              {/* <div className="text-center">
                <p className=" text-xl">
                  <b>Bank Account Details</b>
                </p>
                <p>Name: Kajal Dattatrey Patil </p>
                <p>Bank name : Bank of Baroda </p>
                <p>Branch : HAL Chinchwad branch</p>
                <p>Account number : 30770100021918 </p>
                <p>IFSC code : BARB0VJCHIN</p>
              </div> */}

              {/* <hr className="hr-text" data-content="OR" /> */}
              <div className="flex justify-center">
                {/* <img src={qr} alt="QR CODE" className="w-32" /> */}
              </div>
              <p className="text-2xl text-black-600/100">
                <b>Money to be tranferred: {totalAmount}</b>
              </p>
              <div className="flex gap-3">
                <input
                  type="text"
                  placeholder="UPI/NEFT/IMPS"
                  className="w-full"
                  {...register("modeOfPayment", {
                    required: true,
                  })}
                />

                <input
                  type="text"
                  placeholder="Transaction Number"
                  className="w-full"
                  {...register("transactionNumber", {
                    required: true,
                  })}
                />
              </div>
              <p className="text-red-600/100">
                {errors.modeOfPayment ? errors.modeOfPayment.message : ""}
              </p>
              <p className="text-red-600/100">
                {errors.transactionNumber
                  ? errors.transactionNumber.message
                  : ""}
              </p>
              <button className="email-btn" onClick={() => onSubmit()}>
                Complete Order
              </button>
            </>
          )}
        </div>
        {/* <div
          className={`flex flex-col gap-5 border-2 shadow-xl p-10 purchase-form ${
            4 > activeStep ? "opacity-50" : ""
          }`}
        >
          <p className="text-2xl font-semibold">4. Review & Purchase</p>
          {renderContent(4, <></>)}
        </div> */}
      </div>
    </section>
  );
};

export default Checkout;
