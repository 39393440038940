import React, { useEffect, useState } from "react";
import "./productInfo.scss";
import { useParams } from "react-router-dom";
import { useCartContext } from "../../context/cartContext";
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyA3EKM4mOUuWE74-xaKJeDuzox0ekplrjA",
  authDomain: "the-non-dit.firebaseapp.com",
  projectId: "the-non-dit",
  storageBucket: "the-non-dit.appspot.com",
  messagingSenderId: "1017386525662",
  appId: "1:1017386525662:web:0026383ca5d250460db34f",
  measurementId: "G-7MCKH424RF",
};
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const imageFetchingLogic = async (imageName) => {
  if (imageName) {
    // console.log("we are inside imagefetching logic")
    const fileRef = ref(storage, imageName);
    const url = await getDownloadURL(fileRef);
    // console.log("url before returning", url)
    return url;
  }
};
const ProductInfo = ({ productInfo }) => {
  const { addToCart } = useCartContext();
  const { productId } = useParams();
  const [isQuantity, setIsQuantity] = useState(1);
  const [isSize, setIsSize] = useState(productInfo?.variantDetails[0].label);
  const [isError, setIsError] = useState(false);
  const [isQuanError, setIsQuanError] = useState(false);
  const [sizeGuide, setSizeGuide] = useState(false);
  const [imageUrls, setImageUrls] = useState({});
  const [selectedVariant, setSelectedVariant] = useState(
    productInfo?.variantDetails[0]
  );

  const fetchAllImageUrls = async (imageNames) => {
    const allUrls = {};

    for (const imageName of imageNames) {
      const url = await imageFetchingLogic(imageName);
      allUrls[imageName] = url;
      setImageUrls((prevUrls) => ({ ...prevUrls, [imageName]: url }));
    }

    return allUrls;
  };
  useEffect(() => {
    async function loadAllUrls() {
      // console.log("imagesin 2nd", images)
      if (productInfo?.sizeGuide) {
        const urls = await fetchAllImageUrls(productInfo?.sizeGuide);
        setImageUrls(urls);
      }
    }

    loadAllUrls();
  }, [productInfo?.sizeGuide]);

  const getCurrentImageUrl = (imageName) => {
    return imageUrls[imageName];
  };
  const handleSizeGuide = () => {
    setSizeGuide(!sizeGuide);
  };

  useEffect(() => {
    setSelectedVariant(productInfo?.variantDetails[0]); // Set the default variant on component mount
  }, [productInfo]);

  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const maxStock = selectedVariant?.stock || 100; // Default max stock value

    if (value >= 1 && value <= maxStock) {
      setIsQuantity(value);
      setIsQuanError(false); // Clear the error when within valid range
    } else if (value > maxStock) {
      setIsQuantity(maxStock);
      setIsQuanError(true); // Show the error for exceeding stock limit
    }
    // if (value >= 1 && value <= 100) {
    //   setIsQuantity(value);
    // }
  };
  const handleSizeChange = (e) => {
    const value = e.target.value;
    if (value === "NaN") {
      setIsSize(null);
      setIsError(true);
    } else {
      setIsSize(value);
      setIsError(false);
      const selectedVariant = productInfo?.variantDetails.find(
        (variant) => variant.size === value
      );
      setSelectedVariant(selectedVariant);
    }
  };

  const handleAddToCart = () => {
    if (isSize === undefined) {
      setIsError(true);
      return;
    }
    if (isQuantity < 1) {
      return;
    }
    if (isError === true || isQuanError === true) return;

    addToCart({
      quantity: isQuantity < 1 ? 1 : isQuantity,
      product: productInfo,
      size: isSize,
      price: selectedVariant.price,
      stock: Number(selectedVariant.stock),
    });
  };
  const handleQuantity = (operator) => {
    if (operator === "+") setIsQuantity(isQuantity + 1);
    else setIsQuantity(isQuantity - 1);
  };
  return (
    <section className="info-section flex flex-col gap-1  text-black">
      <h2 className="text-xl font-bold md:text-left text-left">
        {productInfo?.name}
      </h2>
      <h2 className="text-xl md:text-left text-left">{`$${selectedVariant?.price}`}</h2>
      <div className="flex md:flex-col flex-col gap-3">
        <div className="flex flex-col gap-2">
          <div className="mt-2">
            <p className=" uppercase  font-extrabold">description</p>
            <h2 className="description">{productInfo?.description}</h2>
          </div>
          {productId ? (
            <div>
              <p className=" uppercase font-extrabold">details</p>
              {productInfo?.additionalInfo?.map((detail, id) => (
                <li key={id} className="ml-5 details">
                  {detail}
                </li>
              ))}
            </div>
          ) : null}
          <h2 className=" cursor-pointer description" onClick={handleSizeGuide}>
            Size Guide
          </h2>
        </div>
        <div>
          <div className="mt-2 flex items-center">
            <div>
              <label className="block font-extrabold">Size:</label>
              <select
                className={`p-5  ${isError ? "error-border" : ""}`}
                onChange={handleSizeChange}
                defaultValue={productInfo?.variantDetails[0]?.size}
              >
                <option value="NaN">Select Size</option>
                {productInfo?.variantDetails?.map((variant) => (
                  <option
                    key={variant.size}
                    value={variant.size}
                    disabled={variant.stock === 0}
                  >
                    {variant.size}
                  </option>
                ))}
              </select>
              {isError && (
                <p className="text-red-700 mt-1">Please select a valid size!</p>
              )}
            </div>
            <div className="ms-10 md:ms-16">
              <label className="block font-extrabold">Quantity:</label>
              <div className="flex flex-1 items-center gap-2 text-xl">
                <button className="" onClick={() => handleQuantity("-")}>
                  -
                </button>
                <input
                  type="number"
                  min="1"
                  max={`${selectedVariant?.stock}`}
                  onChange={handleQuantityChange}
                  value={isQuantity}
                  placeholder="1"
                  required
                />
                <button
                  className=""
                  onClick={() => {
                    handleQuantity("+");
                  }}
                >
                  +
                </button>
              </div>
            </div>
            {selectedVariant?.stock <= 5 && (
              <p className="text-yellow-700 mt-1 text-xl">Selling fast!</p>
            )}
            {isQuanError && (
              <p className="text-red-700 mt-1 text-xl">
                Limited stock available!
              </p>
            )}
          </div>
          <div className="mt-3"></div>
          <button className="modal-btn" onClick={handleAddToCart}>
            Add To Cart
          </button>
        </div>
      </div>
      {sizeGuide ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                {/* <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Size Guide</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={handleSizeGuide}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div> */}
                {/*body*/}
                <div className="relative flex-auto">
                  {productInfo.sizeGuide && (
                    <img
                      src={getCurrentImageUrl(productInfo?.sizeGuide[0])}
                      alt="Size Guide"
                      className="sizeGuide "
                    />
                  )}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                  {/* <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSizeGuide}
                  >
                    Close
                  </button> */}
                  <button
                    className="border border-black px-3 text-xl rounded ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSizeGuide}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </section>
  );
};

export default ProductInfo;
