import React, { useState } from "react";
import "./contact.scss";
import contactImg from "../../assests/contact/28bb759cb701d93ecebfddc719a91f13.jpg";
import InputField from "../../component/inputField/inputField";
import axios from "axios";
const Contact = () => {
  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  };
  const [warningMessage, setWarningMessage] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = () => {
    if (
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      formData.subject &&
      formData.message
    ) {
      axios
        .post(
          " https://sharedclients.ocpl.tech/api/theNonDitEdit/postContact",
          formData
        )
        .then((response) => {})
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      setFormData(initialFormData);
      setWarningMessage(""); // Clear any previous warning messages
    } else {
      setWarningMessage("Please fill out all the fields.");
    }
  };
  return (
    <section className="container">
      <div className=" flex gap-10 md:flex-row flex-col">
        <div className="img-container flex flex-col gap-7 flex-1 ">
          <h2 className="text-3xl">Contact us</h2>
          <img
            src={contactImg}
            className="object-cover object-center w-full contact-img"
            alt=""
          ></img>
        </div>
        <div className="contact-form flex flex-col gap-10 flex-1">
          <div className="flex flex-col gap-3">
            <p className="text-xl">Name (Required)</p>
            <div className="flex w-full gap-5">
              <div className="w-1/2">
                <InputField
                  label="First Name"
                  name="firstName"
                  placeholder="Enter your first name"
                  required={true}
                  className="w-full"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-1/2">
                <InputField
                  label="Last Name"
                  name="lastName"
                  placeholder="Enter your last name"
                  className="w-full"
                  required={true}
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <InputField
              label="Email (required)"
              name="email"
              placeholder="Enter your Email"
              required={true} // Required for validation
              value={formData.email} // Pass the value from the state
              onChange={handleInputChange} // Pass the event handler
            />
            <InputField
              label="Subject (required)"
              name="subject"
              placeholder="Enter your Subject"
              required={true} // Required for validation
              value={formData.subject} // Pass the value from the state
              onChange={handleInputChange} // Pass the event handler
            />
            <InputField
              label="Message (required)"
              name="message"
              placeholder="Enter your Messsage"
              required={true} // Required for validation
              value={formData.message} // Pass the value from the state
              onChange={handleInputChange} // Pass the event handler
            />
          </div>
          <div>
            {warningMessage && (
              <p className="text-red-500 text-xl">{warningMessage}</p>
            )}
            <button
              className="contact-btn uppercase"
              onClick={handleFormSubmit}
            >
              send
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
