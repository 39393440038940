import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./component/navbar/navbar";
import Footer from "./component/footer/footer";
import Home from "./page/home/home";
import About from "./page/about/about";
import Shop from "./page/shop/shop";
import Contact from "./page/contact/contact";
import Cart from "./page/cart/cart";
import SingleProduct from "./page/singleProduct/singleProduct";
import { CartProvider } from "./context/cartContext";
import Checkout from "./page/checkout/checkout";

function App() {
  return (
    <Router>
      <CartProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<Shop />} />
          <Route path="/about" element={<About />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/product/:productId" element={<SingleProduct />} />
          <Route path="/checkout" element={<Checkout />} />
        </Routes>
        <Footer />
      </CartProvider>
    </Router>
  );
}

export default App;
