import { createContext, useEffect, useReducer } from "react";
import reducer from "../reducer/cartReducer";
import { useContext } from "react";
import { toastify } from "../component/helpers/toast";

const cartContext = createContext();

const getLocalCartData = () => {
  let { cart } = localStorage.getItem("non-dit") || "null" || "undefined";

  if (cart === null || cart === undefined || cart.length === 0) {
    return [];
  } else {
    return JSON.parse(cart);
  }
};

const initialState = {
  filterProducts: [],
  filters: {
    category: "all",
  },
  cart: getLocalCartData(),
  totalItem: 0,
  totalAmount: "",
};

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addToCart = ({ quantity, product, size, price, stock }) => {
    dispatch({
      type: "ADD_TO_CART",
      payload: { quantity, product, size, price, stock },
    });
    toastify({ msg: "Added to Cart!", type: "success" });
    // navigate("/cart");
    // Swal.fire({
    //   title: "Added to Cart",
    //   icon: "success",
    // });
    // navigate("/shop");
  };

  const removeItem = (id, size) => {
    dispatch({ type: "REMOVE_ITEM", payload: { id, size } });
  };

  const increaseAmount = (id, prevQuantity, size) => {
    dispatch({ type: "INCREASE_AMOUNT", payload: { id, prevQuantity, size } });
  };
  const decreaseAmount = (id, Quantity, size) => {
    dispatch({ type: "DECREASE_AMOUNT", payload: { id, Quantity, size } });
  };
  const updateQuantity = (id, newQuantity, size) => {
    dispatch({ type: "UPDATE_QUANTITY", payload: { id, newQuantity, size } });
  };

  useEffect(() => {
    dispatch({ type: "TOTAL_AMOUNT" });
    const dataToStore = {
      cart: state.cart,
      totalItem: state.totalItem,
    };

    localStorage.setItem("non-dit", JSON.stringify(dataToStore));
  }, [state.cart, state.totalItem]);

  return (
    <cartContext.Provider
      value={{
        ...state,
        addToCart,
        removeItem,
        increaseAmount,
        decreaseAmount,
        updateQuantity,
      }}
    >
      {children}
    </cartContext.Provider>
  );
};

const useCartContext = () => {
  return useContext(cartContext);
};

export { cartContext, CartProvider, useCartContext };
