import React from "react";
import "./loader.scss";
const Loader = () => {
  return (
    <div className="loader-container m-20 p-20">
      <div className="loader-content">
        <span className="loader">
          <span className="loader-inner"></span>
        </span>
      </div>
    </div>
  );
};

export default Loader;
