export const shopSidebar =[
    {
        id:1,
        navLink:"All",
        // href:"/shop"
    },
    // {
    //     id:2,
    //     navLink:"Tops",
    //     // href:"/shop"
    // },
    // {
    //     id:3,
    //     navLink:"Bottoms",
    //     // href:"/about"
    // },
    // {
    //     id:4,
    //     navLink:"Sale",
    //     // href:"/contact"
    // },
]