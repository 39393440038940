import React from "react";

const InputField = ({
  label,
  name,
  placeholder,
  className,
  required = false,
  value,
  onChange,
}) => {
  return (
    <div className={`flex flex-col gap-3`}>
      {label && <label className="font-medium text-lg mt-4">{label}</label>}
      <input
        type="text"
        name={name}
        placeholder={placeholder ? placeholder : null}
        className={`${className}`}
        required={required}
        value={value} // Pass the value from the parent component
        onChange={onChange} // Step 2: Attach the 'onChange' event handler
      />
    </div>
  );
};

export default InputField;
