export const navbarData =[
    {
        id:1,
        navLink:"Lookbook",
        href:"/"
    },
    {
        id:2,
        navLink:"Shop",
        href:"/shop"
    },
    {
        id:3,
        navLink:"About",
        href:"/about"
    },
    {
        id:4,
        navLink:"Contact",
        href:"/contact"
    },
]