import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProductInfo from "../../component/productInfo/productInfo";
import ProductImages from "../../component/productImages/productImages";
import axios from "axios";
const sampleProducts = [
  {
    name: "Organic Apple Juice",
    description: "A refreshing and healthy organic apple juice made from fresh apples.",
    additionalInfo: [
      "500ml bottle",
      "No added sugar",
      "Organic certification"
    ],
    images: [
      "https://d3cm4d6rq8ed33.cloudfront.net/media/navpravartakfiles/19/07ff6acb-31e0-40dc-9d2f-2a2d259a74cd.jpg  ",
      "https://www.shutterstock.com/image-photo/apple-juice-glass-surrounded-by-600nw-1722330664.jpg"
    ],
    variantDetails: [
      { size: "500ml", price: 4.99, stock: 50 },
      { size: "1L", price: 7.99, stock: 30 }
    ],
    visibility: "Public",
    productId: "PROD001"
  },
  {
    name: "Gluten-Free Brown Bread",
    description: "A soft and delicious gluten-free bread made from whole grains.",
    additionalInfo: [
      "500g loaf",
      "Gluten-free",
      "High in fiber"
    ],
    images: [
      "https://static.toiimg.com/photo/90104124.cms",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbmCicJ4AAFBTbPnHuoxvnFfo4JTC5d3oKPQ&s"
    ],
    variantDetails: [
      { size: "500g", price: 3.49, stock: 20 },
      { size: "750g", price: 4.99, stock: 15 }
    ],
    visibility: "Public",
    productId: "PROD002"
  },
  {
    name: "Green Tea Pack",
    description: "A pack of refreshing and antioxidant-rich green tea bags.",
    additionalInfo: [
      "20 tea bags",
      "Rich in antioxidants",
      "Eco-friendly packaging"
    ],
    images: [
      "https://4.imimg.com/data4/QR/JR/MY-9361411/pure-green-tea.jpg",
      "https://tiimg.tistatic.com/fp/1/007/722/100-natural-ingredients-delicious-tetley-green-tea-powder-with-100-grams-packet--671.jpg"
    ],
    variantDetails: [
      { size: "20 bags", price: 5.99, stock: 40 },
      { size: "50 bags", price: 12.99, stock: 25 }
    ],
    visibility: "Public",
    productId: "PROD003"
  }
];


const SingleProduct = () => {
  const { productId } = useParams();
  const [products, setProducts] = useState(sampleProducts);

  React.useEffect(() => {
    axios
      .get(` https://sharedclients.ocpl.tech/api/theNonDitEdit/getProducts`)
      .then((response) => {
        setProducts(response.data);
        // console.log(response.data)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const productDetails = products.filter(
    (product) => product.productId === productId
  );
  // console.log(productDetails)
  return (
    <section className="container">
      <div className="single-header">
        <Link to="/shop">Shop&nbsp;</Link>
        <span>
          &nbsp;{">"}&nbsp;{productDetails[0]?.name}
        </span>
      </div>
      <div className="body flex p-5 gap-5 md:flex-row flex-col">
        <div className=" flex product-image flex-1">
          <ProductImages images={productDetails[0]?.images} />
        </div>
        <div className="product-info flex-1">
          <ProductInfo productInfo={productDetails[0]} />
        </div>
      </div>
    </section>
  );
};

export default SingleProduct;
