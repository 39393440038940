const CartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      let { quantity, product, size, price,stock } = action.payload;
      let totalItemQuatity = state.totalItem + quantity;
      let existingProduct = state.cart.find((curElem) => {
        return curElem.id === product.productId && curElem.size === size;
      });
      if (existingProduct) {
        let updateProductQuantity = state.cart.map((curElem) => {
          if (
            curElem.id === existingProduct.id &&
            curElem.size === existingProduct.size
          ) {
            let newQuantity = curElem.quantity + quantity;
            return {
              ...curElem,
              quantity: newQuantity,
            };
          } else {
            return curElem;
          }
        });

        return {
          ...state,
          cart: updateProductQuantity,
          totalItem: totalItemQuatity,
        };
      } else {
        let cartProduct;
        cartProduct = {
          id: product.productId,
          name: product.name,
          quantity,
          size,
          price,
          image: product.images[0],
          stock,
        };
        return {
          ...state,
          cart: [...state.cart, cartProduct],
          totalItem: totalItemQuatity,
        };
      }

    case "REMOVE_ITEM":
      let { id, size: itemSize } = action.payload;
      let updatedCart;

      let itemBeingRemoved = state.cart.find(
        (curElem) => curElem.id === id && curElem.size === itemSize
      );
      let removedItemQuantity = itemBeingRemoved
        ? itemBeingRemoved.quantity
        : 0;

      updatedCart = state.cart.filter((curElem) => {
        return !(curElem.id === id && curElem.size === itemSize);
      });

      return {
        ...state,
        cart: updatedCart,
        totalItem: state.totalItem - removedItemQuantity,
      };

    case "INCREASE_AMOUNT":
      const { id: incId, prevQuantity, size: incSize } = action.payload;
      const updatedCartIncrease = state.cart.map((item) => {
        if (item.id === incId && item.size === incSize && item.quantity < item.stock) {
          return { ...item, quantity: prevQuantity + 1 };
        }
        return item;
      });

      return {
        ...state,
        cart: updatedCartIncrease,
        totalItem: state.totalItem + 1,
      };

    case "DECREASE_AMOUNT":
      const { id: decId, Quantity, size: decSize } = action.payload;
      const updatedCartDecrease = state.cart.map((item) => {
        if (item.id === decId && Quantity > 1 && item.size === decSize) {
          return { ...item, quantity: Quantity - 1 };
        }
        return item;
      });

      return {
        ...state,
        cart: updatedCartDecrease,
        totalItem: Quantity > 1 ? state.totalItem - 1 : state.totalItem,
      };

    case "TOTAL_AMOUNT":
      let totalOrderAmount = state.cart.reduce((previousValue, curElem) => {
        return previousValue + curElem.quantity * curElem.price;
      }, 0);
      return {
        ...state,
        totalAmount: totalOrderAmount,
      };
    case "UPDATE_QUANTITY":
      let { id: prodId, newQuantity, size: prodSize } = action.payload;
      const updatedProductCart = state.cart.map((item) => {
        if (item.id === prodId && item.size === prodSize) {
          return { ...item, quantity: newQuantity };
        }
        return item;
      });
      const updatedTotalItems = updatedProductCart.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
      return {
        ...state,
        cart: updatedProductCart,
        totalItem: updatedTotalItems,
      };

    default:
      return state;
  }
};

export default CartReducer;
