import React, { useEffect, useRef, useState } from "react";
import "./shop.scss";

const LazyImage = ({ imageUrl, onImageLoad }) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    if (imgRef.current) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setLoaded(true);
            onImageLoad();
            observer.disconnect();
          }
        });
      });

      observer.observe(imgRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [onImageLoad]);

  return (
    <>
      {loaded ? (
        <img
          src={imageUrl}
          alt=""
          className="product-cover"
          style={{"width":"300px", "height":"200px"}}
  
        />
      ) : (
        <div ref={imgRef} className="image-placeholder"></div>
      )}
    </>
  );
};

export default LazyImage;
