import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineInstagram } from "react-icons/ai";

const MobileNavbar = ({ setIsToggle, isToggle, navbarData, pathname }) => {
  return (
    <div
      className={`md:top-36 top-28 left-0  w-full bg-white p-4 text-black fixed h-full z-40 ease-in-out duration-300 mobile-menu overflow-auto whitespace-normal ${
        !isToggle ? "translate-y-0 " : "translate-y-full"
      }`}
    >
      <div className="container flex justify-between flex-col ">
        <div className=" mt-20">
          <ul className="flex justify-between items-center flex-col gap-10">
            {navbarData.map(({ id, navLink, href }) => (
              <li className="text-2xl" key={id}>
                <Link
                  to={href}
                  className={pathname === href ? "underline" : ""}
                  onClick={() => setIsToggle(true)}
                >
                  {navLink}
                </Link>
              </li>
            ))}
            <li className="text-2xl">
              <Link
                to="/"
                target="_blank"
                onClick={() => setIsToggle(true)}
              >
                <AiOutlineInstagram />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
